<template>
	<standard-container title="Impostazioni" description="" icon="mdi-format-list-checks">
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab key="tabImpostazioni">
					{{ gTranslate('Impostazioni') }}
				</v-tab>
				<v-tab key="tabChangePassword">
					{{ gTranslate('Cambio password') }}
				</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-tabs-items v-model="currentTab">
					<!-- tabImpostazioni -->
					<v-tab-item key="tabImpostazioni">
						<v-list>
							<!-- tema -->
							<v-list-item>
								<v-list-item-icon>
									<v-icon v-if="darkTheme">
										mdi-lightbulb-on-outline
									</v-icon>
									<v-icon v-else>
										mdi-lightbulb-on
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ gTranslate('Tema', { keyPrefix: 'tema_'}) }}</v-list-item-title>
									<v-list-item-subtitle>{{ gTranslate('Cambia il colore dell\'interfaccia', { keyPrefix: 'tema_'}) }}</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn v-if="darkTheme" small light @click="toggleTheme">
										{{ gTranslate('chiaro', { keyPrefix: 'tema_'}) }}
									</v-btn>
									<v-btn v-else small dark @click="toggleTheme">
										{{ gTranslate('scuro', { keyPrefix: 'tema_'}) }}
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider />
							
							<!-- debug  -->
							<v-list-item v-if="currentUser.IsAdmin">
								<v-list-item-icon>
									<v-icon>mdi-bug</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ gTranslate('Debug mode', { keyPrefix: 'debug_'}) }}</v-list-item-title>
									<v-list-item-subtitle>{{ gTranslate('Attiva la visualizzazione degli elementi di debug', { keyPrefix: 'debug_'}) }}</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn v-if="viewDebugElements" small color="error" @click="toggleDebugElements">
										{{ gTranslate('disattiva', { keyPrefix: 'debug_'}) }}
									</v-btn>
									<v-btn v-else small color="success" @click="toggleDebugElements">
										{{ gTranslate('attiva', { keyPrefix: 'debug_'}) }}
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider />
							
							<!-- tce  -->
							<v-list-item>
								<v-list-item-icon>
									<v-icon>mdi-border-bottom-variant</v-icon>
								</v-list-item-icon>
								<v-list-item-content style="align-self:start">
									<v-list-item-title>
										{{ gTranslate('Tipologia campi edit', { keyPrefix: 'edit_'}) }}
									</v-list-item-title>
									<v-list-item-subtitle>{{ gTranslate('Definisce l\'aspetto grafici dei campi di edit', { keyPrefix: 'edit_'}) }}</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-list-item-action-text>
										<div class="d-flex flex-column justify-space-between" style="min-height:180px; min-width:250px;margin-top:10px">
											<div class="d-flex">
												<v-text-field
													:filled="tceFilled" 
													:solo="tceSolo" 
													:outlined="tceOutlined" 
													:dense="tceDense" 
													:label="gTranslate('Esempio', { keyPrefix: 'edit_'})" 
													:value="gTranslate('testo esempio', { keyPrefix: 'edit_'})"
												/>
											</div>
											<div class="">
												<v-switch v-model="tceFilled" class="mt-1" dense label="Filled" />
												<v-switch v-model="tceSolo" class="mt-1" dense label="Solo" />
												<v-switch v-model="tceOutlined" class="mt-1" dense label="Outlined" />
												<v-switch v-model="tceDense" class="mt-1" dense label="Dense" />
											</div>
										</div>
									</v-list-item-action-text>
								</v-list-item-action>
							</v-list-item>
							<v-divider />

							<!-- compact mode  -->
							<v-list-item>
								<v-list-item-icon>
									<v-icon v-if="compactVisualization">
										mdi-view-headline
									</v-icon>
									<v-icon v-else>
										mdi-view-sequential
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content style="align-self:start">
									<v-list-item-title>
										{{ gTranslate('Visualizzazione compatta', { keyPrefix: 'compact_'}) }}
									</v-list-item-title>
									<v-list-item-subtitle>{{ gTranslate('Attiva una modalità di visualizzazione più compatta', { keyPrefix: 'compact_'}) }}</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn v-if="compactVisualization" small color="error" @click="toggleCompactVisualization">
										{{ gTranslate('disattiva', { keyPrefix: 'compact_'}) }}
									</v-btn>
									<v-btn v-else small color="success" @click="toggleCompactVisualization">
										{{ gTranslate('attiva', { keyPrefix: 'compact_'}) }}
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider />

							<!-- phone open  -->
							<v-list-item v-if="currentUser.IsAdmin || currentUser.IsSuperuser">
								<v-list-item-icon>
									<v-icon v-if="cellularOpenMode">
										mdi-phone
									</v-icon>
									<v-icon v-else>
										mdi-whatsapp
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content style="align-self:start">
									<v-list-item-title>
										{{ gTranslate('Modalità apertura cellulare', { keyPrefix: 'phone_'}) }}
									</v-list-item-title>
									<v-list-item-subtitle>{{ gTranslate('Sceglie tra la modalità dialer (solo per mobile) o quella whatsapp', { keyPrefix: 'phone_'}) }}</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn v-if="cellularOpenMode" small color="success" @click="toggleCellularOpenMode">
										{{ gTranslate('whatsapp', { keyPrefix: 'phone_'}) }}
									</v-btn>
									<v-btn v-else small color="info" @click="toggleCellularOpenMode">
										{{ gTranslate('dialer', { keyPrefix: 'phone_'}) }}
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider />


							<!-- after save  -->
							<v-list-item v-if="currentUser.IsAdmin || currentUser.IsSuperuser">
								<v-list-item-icon>
									<v-icon v-if="personeExitAfterSave">
										mdi-page-previous-outline
									</v-icon>
									<v-icon v-else>
										mdi-page-next-outline
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content style="align-self:start">
									<v-list-item-title>
										<v-chip x-small outlined label color="warning" class="mr-1">
											BETA
										</v-chip> {{ gTranslate('Lista persone dopo salvataggio persona', { keyPrefix: 'lpers_'}) }}
									</v-list-item-title>
									<v-list-item-subtitle>{{ gTranslate('Determina se dopo il salvataggio di una persona si rientri sulla lista o meno', { keyPrefix: 'lpers_'}) }}</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn v-if="personeExitAfterSave" small color="success" @click="togglePersoneExitAfterSave">
										{{ gTranslate('SI', { keyPrefix: 'lpers_'}) }}
									</v-btn>
									<v-btn v-else small color="info" @click="togglePersoneExitAfterSave">
										{{ gTranslate('NO', { keyPrefix: 'lpers_'}) }}
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider />
						</v-list>						
					</v-tab-item>
					<!-- tabChangePassword -->
					<v-tab-item key="tabChangePassword">
						<change-password />
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
		
		<!-- debug -->
		<template v-slot:debug>
			<v-row>
				<v-col cols="12" md="6">
					<debug-panel label="tce">
						{{ tce }}
					</debug-panel>
				</v-col>
				<v-col cols="12" md="6">
					<debug-panel label="compactVisualization">
						{{ compactVisualization }}
					</debug-panel>
				</v-col>
			</v-row>
		</template>		
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** Pagina delle impostazioni utente e del cambio pwd ****************************************
***********************************************************************************************
*/
import StandardContainer from '@/components/standard-container'
import DebugPanel from '@/components/debug-panel'
import ChangePassword from '@/components/change-password'
export default {
	name: '',
	components: { StandardContainer, DebugPanel, ChangePassword },
	data: () => {
		return {
			currentTab: 0,
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		darkTheme() { return this.$vuetify.theme.dark },
		tce() { return JSON.parse(JSON.stringify(this.$store.getters['tipologiaCampiEdit'])) },
		currentUser() { return this.$store.getters['currentUser'] },
		tceFilled: {
			get() {
				return this.tce.filled 
			},
			set(value) {
				var tce = this.tce
				tce.filled = value
				// console.warn('tceFilled.set', tce)
				this.$store.dispatch('setTipologiaCampiEdit', tce)
			}
		},
		tceOutlined: {
			get() {
				return this.tce.outlined 
			},
			set(value) {
				var tce = this.tce
				tce.outlined = value
				// console.warn('tceOutlined.set', tce)
				this.$store.dispatch('setTipologiaCampiEdit', tce)
			}
		},
		tceSolo: {
			get() {
				return this.tce.solo 
			},
			set(value) {
				var tce = this.tce
				tce.solo = value
				// console.warn('tceSolo.set', tce)
				this.$store.dispatch('setTipologiaCampiEdit', tce)
			}
		},
		tceDense: {
			get() {
				return this.tce.dense 
			},
			set(value) {
				var tce = this.tce
				tce.dense = value
				// console.warn('tceDense.set', tce)
				this.$store.dispatch('setTipologiaCampiEdit', tce)
			}
		},
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
		personeExitAfterSave() { return this.$store.getters['personeExitAfterSave'] },
	},
	beforeRouteEnter (to, from, next) { // usare sempre questa tecnica perché il classico watch $route non funziona.... non so perché (bug #1057)
		next(that => {
			// that.caricaTraduzioni()
			that.currentTab = parseInt(that.$route.params.tab)
		})
	},
	mounted() {
	},
	methods: {
		toggleTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark
			this.$store.dispatch('toggleTheme', this.$vuetify.theme.dark)
		},
		toggleDebugElements() {
			this.$store.dispatch('toggleDebugElements')
		},
		toggleCompactVisualization() {
			this.$store.dispatch('toggleCompactVisualization')
		},
		toggleCellularOpenMode() {
			this.$store.dispatch('toggleCellularOpenMode')
		},
		togglePersoneExitAfterSave() {
			this.$store.dispatch('togglePersoneExitAfterSave')
		},
	},
}
</script>

<style scoped lang="less">

</style>