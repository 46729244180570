<template>
	<v-card v-if="viewDebugElements" color="#385F73" dark>
		<v-card-title v-if="label">
			{{ label }}
		</v-card-title>
		<v-card-title v-else>
			Debug
		</v-card-title>
		<v-card-text>
			<pre><slot /></pre>
		</v-card-text>
	</v-card>
</template>

<script>
/**********************************************************************************************
****  *******************************************************************************
***********************************************************************************************
*/
export default {
	name: 'DebugPanel',
	props: {
		label :{
			type: String,
			default: () => {return ''}
		},
	},	
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] }
	}
}
</script>

<style scoped lang="less">
	pre {
		overflow-x: auto
	}
</style>